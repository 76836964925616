<template>
  <div>
    <quill @outputVal="getVal"></quill>
    <div>---------------------------------------------</div>
    <div>
      <div class="ql-editor" v-html="quillData"></div>
    </div>
  </div>
</template>

<script>
import quill from '@/components/quill/quill'

export default {
  components: {
    quill,
  },
  data() {
    return {
      quillData: ''
    }
  },
  methods: {
    getVal(val) {
      this.quillData = val
    },
  }
}
</script>

<style scoped>
</style>
